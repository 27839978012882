import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Modal, ActionBar, ButtonGroup, Loader } from 'shared-components';
import { HeadingContainer } from './styles';
import SubmitButton from '../../../../components/SubmitButton';
import CancelButton from '../../../../components/CancelButton';
import { transferTypes, page } from './utils';

const TransferModal = ({
  toggleModal,
  confirmCallback,
  feeAmount,
  params,
  type,
  description,
}) => {
  const intl = useIntl();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    confirmCallback(params);
  };

  return (
    <Modal>
      <HeadingContainer>
        <h3>
          {intl.messages[`${page}-confirm-${transferTypes[type].text}-heading`]}
        </h3>
        <p>
          {feeAmount === '' ? (
            intl.messages[
              `${page}-confirm-${transferTypes[type].text}-no-fee-message`
            ]
          ) : (
            <FormattedMessage
              id={`${page}-confirm-${transferTypes[type].text}-message`}
              description={description}
              defaultMessage={`There is a $${feeAmount} fee for this action.`}
              values={{ feeAmount }}
            />
          )}
        </p>
      </HeadingContainer>
      {loading ? (
        <Loader />
      ) : (
        <form onSubmit={e => handleSubmit(e)}>
          <ActionBar>
            <ButtonGroup reverse>
              <SubmitButton />
              <CancelButton
                onClick={e => {
                  e.preventDefault();
                  toggleModal(false);
                }}
              />
            </ButtonGroup>
          </ActionBar>
        </form>
      )}
    </Modal>
  );
};

TransferModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  confirmCallback: PropTypes.func.isRequired,
  params: PropTypes.shape({
    amount: PropTypes.number,
    transfer_type: PropTypes.string,
    external_account_id: PropTypes.string,
  }),
  feeAmount: PropTypes.string,
  type: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

TransferModal.defaultProps = {
  feeAmount: '',
  params: {},
};

export default TransferModal;
