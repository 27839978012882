import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
// Import Shared Components
import { Loader, PasswordRequirements, Input } from 'shared-components';
// Import Shared Hooks
import { useUpdateStateObject } from 'shared-components/src/hooks/useUpdateStateObject';
// Import Shared Validations
import { verifyRegisterValidations } from 'shared-components/src/validations/cardholderValidations';
// Import Shared Utils
import { parseURLSearchParams } from 'shared-components/src/utils/string';
// Import Global Components
import InfoTooltip from '../../../components/InfoTooltip';
// Import Global Layouts
import InputGroup from '../../../layouts/InputGroup';
// Import Local Layouts
import AuthLayout, { BareAuthLayout } from '../layouts/Auth';
import { AuthForm } from '../layouts/InnerAuth';
// Import Local Components
import InvalidLink from '../components/InvalidLink';
// Import Component Styles
import { FormGroup } from '../styles';
// Import Utils
import { resetPassword, validateToken } from '../../../utils/auth';
import {
  validatePassword,
  validatePasswordConfirmation,
  checkForValidationErrors,
} from '../../../utils/validationHandlers';

const ResetPassword = ({ history }) => {
  // Set States
  const initialRegisterInfo = {
    username: '',
    password: '',
    passwordConfirmation: '',
  };
  const initialValidationErrors = {
    username: '',
    password: '',
    passwordConfirmation: '',
  };
  const [formError, setFormError] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cardholder, setCardholder] = useState({});

  // create intl instance
  const intl = useIntl();

  // Create reducer with initial card information state
  // Returns the updated state to be passed to components and a function to update the state
  const [resetPasswordInfo, setResetPasswordInfo] = useUpdateStateObject(
    initialRegisterInfo
  );
  const [validationErrors, setValidationErrors] = useUpdateStateObject(
    initialValidationErrors
  );

  // Validate token on page load
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const handleValidateToken = async () => {
      const { token } = parseURLSearchParams(window.location.search);

      if (!token) {
        setError(true);
        setLoading(false);
      }

      try {
        setLoading(true);
        const res = await validateToken(token);
        setCardholder(res);
        setLoading(false);
      } catch (e) {
        setError(true);
      }
    };

    handleValidateToken();
  }, []);

  if (error) return <InvalidLink />;
  if (loading)
    return (
      <BareAuthLayout>
        <Loader />
      </BareAuthLayout>
    );

  const handleResetPassword = async payload => {
    try {
      setLoading(true);
      setFormError(false);
      await resetPassword(payload);
      setResetPasswordInfo({ key: 'username', value: '' });
      setResetPasswordInfo({ key: 'password', value: '' });
      setResetPasswordInfo({ key: 'passwordConfirmation', value: '' });
      setValidationErrors({ key: 'username', value: '' });
      setValidationErrors({ key: 'password', value: '' });
      setValidationErrors({ key: 'passwordConfirmation', value: '' });
      history.push('/login', {
        success: 'password-reset-success-alert',
      });
    } catch (e) {
      setFormError(e.message);
    }
  };

  // Set Handlers
  // eslint-disable-next-line consistent-return
  const handleSubmit = async () => {
    // Check if password meets requirements
    if (!verifyRegisterValidations.password(resetPasswordInfo.password))
      return setFormError('invalid-password-format-error');
    // Check if password and password confirmation match
    if (resetPasswordInfo.password !== resetPasswordInfo.passwordConfirmation)
      return setFormError('invalid-password-confirmation-error');

    // Throws a generic error message if any of the fields are not valid
    if (
      Object.keys(validationErrors).find(field => {
        return validationErrors[field] === true;
      })
    )
      return setFormError('invalid-form-submit-error');

    // Create payload to be sent
    const payload = {
      username: cardholder.username,
      password: resetPasswordInfo.password,
      password_confirmation: resetPasswordInfo.passwordConfirmation,
    };

    handleResetPassword(payload);
    setLoading(false);
  };

  const handleRegisterKeyUp = async e => {
    if (e.keyCode === 13) {
      await handleSubmit();
    }
  };

  const page = 'reset-password';

  // TODO: Need to get correct wording for this page from Operations
  return (
    <AuthLayout
      page={page}
      heading={intl.messages[`${page}-heading`]}
      subHeader={intl.messages[`${page}-subheader-text`]}
      error={formError}
    >
      <AuthForm
        page={page}
        loading={loading}
        onKeyUp={handleRegisterKeyUp}
        onSubmit={e => {
          e.preventDefault();
          checkForValidationErrors(validationErrors, setFormError);
          handleSubmit();
        }}
      >
        <FormGroup>
          <InputGroup
            label={intl.messages['auth-username-label']}
            labelFor="username"
            page="auth"
            required
          >
            <Input required disabled value={cardholder.username} />
          </InputGroup>
          <InputGroup
            tooltip={(
              <InfoTooltip
                position="top"
                content={<PasswordRequirements messages={intl.messages} />}
              />
            )}
            label={intl.messages['auth-new-password-label']}
            labelFor="new-password"
            page="auth"
            required
          >
            <Input
              value={resetPasswordInfo.password}
              error={validationErrors.password}
              onBlur={() =>
                validatePassword(setValidationErrors, resetPasswordInfo)}
              onChange={e =>
                setResetPasswordInfo({
                  key: 'password',
                  value: e.target.value.trim(),
                })}
              type="password"
              pattern="(?=.*[\d])(?=.*[A-Z])(?=.*[!@#$*])[\w!@#$*]{8,}"
              minLength={8}
              required
            />
          </InputGroup>
          <InputGroup
            label={intl.messages['auth-password-confirmation-label']}
            labelFor="password-confirmation"
            page="auth"
            required
          >
            <Input
              value={resetPasswordInfo.passwordConfirmation}
              error={validationErrors.passwordConfirmation}
              onBlur={() =>
                validatePasswordConfirmation(
                  setValidationErrors,
                  resetPasswordInfo
                )}
              onChange={e =>
                setResetPasswordInfo({
                  key: 'passwordConfirmation',
                  value: e.target.value.trim(),
                })}
              type="password"
              minLength={8}
              pattern="(?=.*[\d])(?=.*[A-Z])(?=.*[!@#$*])[\w!@#$*]{8,}"
              required
            />
          </InputGroup>
        </FormGroup>
      </AuthForm>
    </AuthLayout>
  );
};

ResetPassword.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ResetPassword;
