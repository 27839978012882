import styled from 'styled-components';
import { Button as UnstyledButton } from 'shared-components';
import UnstyledCard from '../../../../components/Card';
import UnstyledText from '../../../../components/Text';

export const Card = styled(UnstyledCard)`
  min-width: 250px;
  height: 175px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  & > * {
    text-align: center;
    margin: 0 auto;
  }

  & > *:first-child {
    margin-bottom: 15px;
  }

  & > *:last-child {
    margin-bottom: 5px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey};
  }
`;

export const Button = styled(UnstyledButton)`
  padding: 0;
`;

export const Text = styled(UnstyledText)`
    color: ${({ theme }) => theme.brand.primary}
    font-size: 18px;
    letter-spacing: 0;
`;
