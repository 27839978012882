// Translations File for Auth Pages

export const authEn = {
  'login-heading': 'Login',
  'login-subheader1-text': 'Please enter your username and password',
  'login-subheader2-text':
    'If your card is not yet activated, you can do so <activate>here</activate>. If you have not yet registered your card, click <register>here</register> to register your account.  To view or download your cardholder agreement, click <cardholder>here</cardholder>.',
  'login-secondary-button': 'Forgot Password',
  'activate-heading': 'Activate Card',
  'activate-subheader1-text':
    'To activate your card please provide the following information',
  'activate-subheader2-text':
    'If your card is already activated, click <register>here</register> to register your account or login to your account <login>here</login>. To view or download your cardholder agreement, click <cardholder>here</cardholder>.',
  'activate-terms-consent-label': 'By using this card, you agree to the cardholder agreement sent along with this card.',
  'register-heading': 'Register for an Account',
  'register-subheader1-text':
    'To register your card please provide the following information',
  'register-subheader2-text':
    'If your card is not yet activated, you can do so <activate>here</activate>.',
  'register-success-alert': 'Card succesfully registered, logging in...',
  'auth-submit-button': 'Submit',
  'auth-card-number-label': 'Card Number',
  'auth-cvv-label': 'CVV',
  'auth-expiry-label': 'Expiry',
  'auth-username-label': 'Username',
  'auth-password-label': 'Password',
  'auth-current-password-label': 'Current Password',
  'auth-new-password-label': 'New Password',
  'auth-password-confirmation-label': 'Password Confirmation',
  'forgot-password1-heading': 'Request New Password',
  'forgot-password1-subheader1-text':
    'Please enter your username to request a new password. We will email you a link to reset your password.',
  'forgot-password1-primary-button': 'Send Request',
  'forgot-password1-secondary-button': 'Back',
  'forgot-password2-heading': 'Email Sent',
  'forgot-password1-email-sent-text':
    'A password reset link will be sent to the email associated with the account. If you do not receive an email, please verify your username and try again. Additionally, we also recommend checking your spam or junk folder.',
  'forgot-password2-primary-button': 'Return to Login',
  'forgot-password2-secondary-button': 'Back',
  'reset-password-heading': 'Reset Password',
  'reset-password-subheader-text':
    'To reset your password please provide the following information.',
  'invalid-link-heading': 'Link is Invalid',
  'invalid-link-subheader-text':
    'Seems the link you\'re trying to use is invalid',
  'invalid-link-reason-text':
    'This may be because the email link you are trying to use has expired. Please request another password reset or contact your Account Manager.',
  'invalid-link-auth-button': 'Request New Password',
  'auth-card-already-activated-message':
    'This card is already activated! Please <a>click here</a> to register your card in order to view your transaction history and update your profile information',
  'auth-card-activated-message':
    'Your card has been activated! Please <a>click here</a> to register your card in order to view your transaction history and update your profile information',
  'auth-card-registered-login-link':
    'If you have already registered your card, please <a>click here</a> to login.',
  'cardholder-agreement-heading': 'Cardholder Agreement',
  'cardholder-agreement-subheader1-text':
    'To view or download your cardholder agreement please provide the following information.',
  'cardholder-agreement-card-proxy': 'Proxy Number',
  'cardholder-agreement-find-card-proxy': 'Find your proxy number',
  'cardholder-agreement-submit-button': 'Submit',
  'cardholder-agreement-wait-button': 'Please wait',
};

export const authFr = {
  'login-heading': 'Connexion',
  'login-subheader1-text':
    'Veuillez saisir votre nom d\'utilisateur et votre mot de passe',
  'login-subheader2-text':
    'Si votre carte n\'est pas encore activée, vous pouvez le faire <activate>ici</activate>. Si vous n\'avez pas encore enregistré votre carte, cliquez <register>ici</register> pour enregistrer votre compte. Pour consulter ou télécharger votre contrat de titulaire de carte, veuillez cliquer <cardholder>ici</cardholder>. ',
  'login-secondary-button': 'Mot de passe oublié',
  'activate-heading': 'Activer la carte',
  'activate-subheader1-text':
    'Pour activer votre carte, veuillez fournir les informations suivantes',
  'activate-subheader2-text':
    'Si votre carte est déjà activée, cliquez <register>ici</register> pour enregistrer votre compte ou connectez-vous à votre compte <login>ici</login>. Pour consulter ou télécharger votre contrat de titulaire de carte, veuillez cliquer <cardholder>ici</cardholder>.',
  'activate-terms-consent-label': 'En utilisant cette carte, vous acceptez l\'entente du titulaire de carte envoyée avec cette carte.',
  'register-heading': 'Créer un compte',
  'register-subheader1-text':
    'Pour enregistrer votre carte, veuillez fournir les informations suivantes',
  'register-subheader2-text':
    'Si votre carte n\'est pas encore activée, vous pouvez le faire <activate>ici</activate>.',
  'auth-submit-button': 'Soumettre',
  'auth-card-number-label': 'Numéro de carte',
  'auth-cvv-label': 'CVV',
  'auth-expiry-label': 'Expiration',
  'auth-username-label': 'Nom d\'utilisateur',
  'auth-password-label': 'Mot de passe',
  'auth-current-password-label': 'Mot de passe actuel',
  'auth-new-password-label': 'Nouveau mot de passe',
  'auth-password-confirmation-label': 'Confirmation du mot de passe',
  'forgot-password1-heading': 'Demander un nouveau mot de passe',
  'forgot-password1-subheader1-text':
    'Veuillez saisir votre nom d\'utilisateur pour demander un nouveau mot de passe. Nous vous enverrons par e-mail un lien pour réinitialiser votre mot de passe.',
  'forgot-password1-secondary-button': 'Retour',
  'forgot-password2-back-button': '',
  'forgot-password2-heading': 'Courriel envoyé',
  'forgot-password1-email-sent-text':
    'Un lien de réinitialisation du mot de passe sera envoyé à l\'email associé au compte. Si vous ne recevez pas d\'e-mail, veuillez vérifier votre nom d\'utilisateur et réessayer. De plus, nous vous recommandons également de vérifier votre dossier de spam ou de courrier indésirable.',
  'forgot-password2-primary-button': 'Retourner à de connexion',
  'forgot-password2-secondary-button': 'Retour',
  'reset-password-heading': 'Réinitialiser le mot de passe',
  'reset-password-subheader-text':
    'Pour réinitialiser votre mot de passe, veuillez fournir les informations suivantes.',
  'invalid-link-heading': 'Le lien est invalide',
  'invalid-link-subheader-text':
    'Il semble que le lien que vous essayez d\'utiliser n\'est pas valide',
  'invalid-link-reason-text':
    'Cela peut être dû au fait que le lien e-mail que vous essayez d\'utiliser a expiré. Veuillez demander une autre réinitialisation de mot de passe ou contactez votre responsable de compte.',
  'invalid-link-auth-button': 'Demander un nouveau mot de passe',
  'auth-card-already-activated-message':
    'Cette carte est déjà activée ! Veuillez <a>cliquer ici</a> pour enregistrer votre carte afin de consulter l\'historique de vos transactions et mettre à jour les informations de votre profil',
  'auth-card-activated-message':
    'Votre carte a été activée ! Veuillez <a>cliquer ici</a> pour enregistrer votre carte afin de consulter l\'historique de vos transactions et mettre à jour les informations de votre profil',
  'cardholder-agreement-heading': 'Entente du titulaire de carte',
  'cardholder-agreement-subheader1-text': 
    'Pour consulter ou télécharger votre entente du titulaire de carte, veuillez fournir les informations suivantes.',
  'cardholder-agreement-card-proxy': 'Numéro de mandataire',
  'cardholder-agreement-find-card-proxy': 'Trouvez votre numéro de mandataire',
  'cardholder-agreement-submit-button': 'Soumettre',
  'cardholder-agreement-wait-button': 'S\'il vous plaît, attendez',
};
