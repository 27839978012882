export const page = 'person-to-external';

export const transferTypes = {
  create: {
    text: 'transfer',
  },
  cancel: {
    text: 'cancel',
  },
};
