import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// Import Shared utils
import { get } from 'shared-components/src/utils/http';
// Import Common Utilss
import { Loader, ErrorComponent } from 'shared-components';

const ProgramConfigContext = React.createContext();

const ProgramConfigProvider = ({ children }) => {
  const [configs, setConfigs] = useState({
    allow_personal_bank_account_transfers: false,
    allow_person_to_person_transfers: false,
    allow_account_to_account_transfer: false,
    allow_child_cards: false,
    allow_sensitive_data_token: false,
    allow_interac_pull_transfers: false,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get cardholder account program configs
        const configData = await get(
          '/api/v1/cardholder_portal/cardholder/program_configs'
        );

        setConfigs(configData.data);
      } catch (e) {
        setError(true);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const renderBody = () => {
    if (loading) return <Loader />;
    if (error) return <ErrorComponent />;

    return <>{children}</>;
  };

  return (
    <ProgramConfigContext.Provider value={configs}>
      {renderBody()}
    </ProgramConfigContext.Provider>
  );
};

ProgramConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ProgramConfigContext, ProgramConfigProvider };
