// Currency Formatting Utils
// Use the default browser internalization tools
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
import {
  isoCountryCodeToStringList,
  locales,
  mergedCurrencyCodes,
} from './countries';

export const formatCurrencyCode = currencyCode =>
  isoCountryCodeToStringList[currencyCode];

// Returns the value formatted with symbol and then Value, example ($50.00) for arguments of 5000, 'cad'
export const formatCurrencySV = (amount, currencyCode, narrow = false) => {
  const currency = mergedCurrencyCodes[currencyCode];
  const opts = {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  // trims the front of any currencies (e.g. CA$100 -> $100)
  if (narrow) {
    opts.currencyDisplay = 'narrowSymbol';
  }

  return `${new Intl.NumberFormat(locales[currency], opts).format(amount)}`;
};

// Returns the value formatted without the currency symbol
export const formatCurrencySVExcludeSymbol = (
  amount,
  currencyCode,
  narrow = false
) => {
  const currency = mergedCurrencyCodes[currencyCode];
  const opts = {
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  if (narrow) {
    opts.currencyDisplay = 'code';
  }

  const formattedAmount = new Intl.NumberFormat(locales[currency], opts).format(
    amount
  );

  const formattedResult = `${currency} ${formattedAmount}`;

  return formattedResult;
};

export const formatCurrencyNarrowSV = (amount, rawCurrency) => {
  const currency = mergedCurrencyCodes[rawCurrency];

  if (!currency) {
    return '';
  }

  return formatCurrencySVExcludeSymbol(amount, rawCurrency, true);
};

export const formatCurrencyCSV = (amount, currencyCode) => {
  const currency = isoCountryCodeToStringList[currencyCode];
  return `${currency} ${formatCurrencySV(amount, currencyCode)}`;
};

export const formatCurrencySVC = (amount, currencyCode) => {
  const currency = isoCountryCodeToStringList[currencyCode];
  return `${formatCurrencySV(amount, currencyCode)} ${currency}`;
};

// Returns value formatted for number type in given locale
export const formatCurrencyV = amount =>
  `${new Intl.NumberFormat('en-CA', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount)}`;

export const formatCurrencyValue = (
  amount,
  isForeignTransaction,
  currencyCode,
  locales
) => {
  const numericAmount = parseFloat(amount);
  let currencySymbol = '';

  if (isForeignTransaction) {
    // For foreign transactions, we convert the currencyCode into a usable 3-letter symbol and set it as the currency symbol
    if (currencyCode === '840') {
      currencySymbol = 'USD';
    } else if (currencyCode === '124') {
      currencySymbol = 'CAD';
    } else {
      // Return an empty string for currencies other than USD or CAD
      return '';
    }

    // Include the currency symbol ($) before the amount
    const formattedAmount = numericAmount.toLocaleString(locales, {
      style: 'currency',
      currency: currencySymbol,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedAmount;
  }

  // For local transactions, include the currency symbol ($) and exclude the currency code by defining the currencyDisplay option as 'symbol'
  const formattedLocalAmount = numericAmount.toLocaleString(locales, {
    currencyDisplay: 'symbol',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  // Include the currency symbol ($) before the local amount and handle negative amounts
  if (numericAmount < 0) {
    return `-$${formattedLocalAmount.slice(1)}`;
  }

  return `$${formattedLocalAmount}`;
};
